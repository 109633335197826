<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="checkAdjustNote()">
      <v-row class="form-container rounded-lg secondary--text">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลการจอง
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <!-- <v-col cols="12">
          <div class="mb-1">
            จำนวนคนเดินทางทั้งหมด
          </div>
          <div
            class="d-flex flex-row align-center"
            style="gap: 1rem;">
            <v-btn
              color="error"
              icon
              :ripple="false"
              :disabled="!formData.followers.length"
              @click="removeFollower()">
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
            <span>
              {{ formData.followers.length }}
            </span>
            <v-btn
              color="primary"
              icon
              :ripple="false"
              @click="addFollower()">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </v-col> -->
        <!-- <v-col cols="12">
          <h3>
            รายละเอียดการจอง
          </h3>
          <v-divider class="mt-3" />
        </v-col> -->
        <v-col
          v-if="isEdit"
          cols="12"
          md="10">
          <div class="mb-1">
            เลขที่การจอง
          </div>
          <div>
            {{ formData.orderNo || '231218-XXXXXX' }}
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            สถานะการจอง
          </div>
          <v-select
            v-model="formData.state"
            :rules="textBoxRules"
            :items="states"
            item-text="text"
            item-value="value"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            แนบสลิป
          </div>
          <file-uploader
            v-model="files"
            :max-file="1"
            label="อัปโหลดสลิป"
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <v-radio-group
            v-model="formData.booker.includeTicket"
            row
            hide-details
            class="mt-0"
            :disabled="loading || isPaid">
            <v-radio
              label="ราคาทัวร์รวมตั่วเครื่องบิน"
              :value="true" />
            <v-radio
              label="ราคาทัวร์ไม่รวมตั๋วเครื่องบิน"
              :value="false" />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            แพ็กเกจทัวร์
          </div>
          <v-autocomplete
            v-model="formData.tour"
            :items="tours"
            item-text="name"
            item-value="id"
            placeholder="EGYPT ไคโร กีซา เมมฟิส อเล็กซานเดรีย"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            return-object
            required
            :disabled="loading || isPaid" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            รอบการเดินทาง
          </div>
          <v-text-field
            v-model="packageText"
            placeholder="โปรดเลือกแพ็กเกจทัวร์"
            color="primary"
            outlined
            dense
            hide-details
            readonly
            required
            :disabled="loading || isPaid" />
        </v-col>
        <!-- <v-col
          cols="12"
          md="10">
          <v-radio-group
            v-model="formData.booker.age"
            row
            hide-details
            class="mt-0">
            <v-radio
              v-for="age in ages"
              :key="`booker-${age.value}`"
              :label="age.text"
              :value="age.value" />
          </v-radio-group>
        </v-col> -->
        <v-col
          cols="12"
          md="10">
          <div class="mb-1">
            รหัสสมาชิก
          </div>
          <v-text-field
            v-model="formData.booker.memberId"
            placeholder="ALLXXXX-XXXXX"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div class="mb-1">
            คำนำหน้า
          </div>
          <v-select
            v-model="formData.booker.prefix"
            :rules="textBoxRules"
            :items="prefixes"
            item-text="text"
            item-value="value"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            ชื่อ
          </div>
          <v-text-field
            v-model="formData.booker.firstName"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            นามสกุล
          </div>
          <v-text-field
            v-model="formData.booker.lastName"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            วัน เดือน ปีเกิด
          </div>
          <DatePickerDialog
            v-model="formData.booker.birthDate"
            format="D MMMM BBBB"
            :rules="textBoxRules"
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            หมายเลขโทรศัพท์
          </div>
          <v-text-field
            v-model="formData.booker.tel"
            type="number"
            :rules="[...textBoxRules, ...telBoxRules]"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            อีเมล
          </div>
          <v-text-field
            v-model="formData.booker.email"
            :rules="emailBoxRules"
            type="email"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
          </div>
          <v-text-field
            v-model="formData.booker.citizenNo"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            ประเภทห้อง
          </div>
          <v-divider class="my-2" />
          <div
            v-if="!tourRooms.length"
            class="accent--text">
            โปรดเลือกแพ็กเกจทัวร์
          </div>
          <v-radio-group
            v-else
            v-model="formData.booker.room.name"
            readonly
            hide-details
            class="mt-0"
            :disabled="loading || isPaid">
            <v-radio
              v-for="room in tourRooms"
              :key="`booker-room-${room.text}`"
              :label="room.text"
              :value="room.name"
              readonly
              @click="selectRoom(room)">
              <template #label>
                <div
                  class="d-flex flex-column flex-md-row align-md-center"
                  style="column-gap: 1rem;">
                  <span class="secondary--text">
                    {{ room.text }}
                  </span>
                  <span
                    v-if="room.price"
                    class="accent--text">
                    {{ `ราคา ${showNumberFormat(room.price)} บาท` }}
                  </span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            อัพเกรดที่นั่ง
          </div>
          <v-divider class="my-2" />
          <div
            v-if="!tourSeats.length"
            class="accent--text">
            โปรดเลือกแพ็กเกจทัวร์
          </div>
          <v-radio-group
            v-else
            v-model="formData.booker.seat.name"
            readonly
            hide-details
            class="mt-0"
            :disabled="loading || isPaid">
            <v-radio
              v-for="seat in tourSeats"
              :key="`booker-seat-${seat.text}`"
              :label="seat.text"
              :value="seat.name"
              readonly
              @click="selectSeat(seat)">
              <template #label>
                <div
                  class="d-flex flex-column flex-md-row align-md-center"
                  style="column-gap: 1rem;">
                  <span class="secondary--text">
                    {{ seat.text }}
                  </span>
                  <span
                    v-if="seat.price"
                    class="accent--text">
                    {{ `ราคา ${showNumberFormat(seat.price)} บาท` }}
                  </span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <!-- <v-col
          cols="12"
          md="10">
          <div>
            ประกันภัย
          </div>
          <v-divider class="my-2" />
          <div
            v-if="!tourRooms.length"
            class="accent--text">
            โปรดเลือกแพ็กเกจทัวร์
          </div>
          <v-expansion-panels
            v-else
            class="rounded-lg mt-3"
            style="border: 1px solid #026EAA;">
            <v-expansion-panel
              v-for="insurance in tourInsurances"
              :key="`booker-insurance-${insurance.name}`"
              class="rounded-lg no-shadow-panel">
              <v-expansion-panel-header>
                <v-checkbox
                  v-model="formData.booker.insurances"
                  :value="insurance.name"
                  :ripple="false"
                  hide-details
                  class="mt-0" />
                <span class="font-weight-medium">
                  {{ insurance.name }}
                </span>
                <span class="font-weight-medium">
                  ราคา {{ showNumberFormat(insurance.price) }} บาท
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="secondary--text">
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <p class="font-weight-medium mb-2">
                      รายละเอียด
                    </p>
                    <p class="accent--text mb-2">
                      {{ insurance.description }}
                    </p>
                    <p class="font-weight-medium mb-2">
                      ความคุ้มครอง
                    </p>
                    <p class="accent--text mb-0">
                      {{ insurance.protections }}
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col> -->
        <v-col
          cols="12"
          md="10">
          <div class="mb-1">
            หมายเหตุ
          </div>
          <v-text-field
            v-model="formData.note"
            placeholder="มีผู้สูงอายุมาด้วย..."
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col cols="12">
          <h3>
            รายชื่อผู้ร่วมเดินทาง
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <v-row>
            <v-col
              cols="12"
              md="3"
              class="d-flex flex-row flex-md-column align-center"
              style="height: fit-content; gap: 1rem; overflow-x: auto;">
              <v-btn
                v-for="(follower, f) in formData.followers"
                :key="`follower-btn-${f}`"
                color="primary"
                outlined
                block
                :ripple="false"
                depressed
                x-large
                @click="selectFollower(f)">
                <div
                  class="d-flex flex-column justify-center"
                  style="gap: 0.5rem;">
                  <span
                    :class="{
                      'text-decoration-underline': f === followerFocusIndex
                    }">
                    ผู้ร่วมเดินทาง {{ f + 1 }}
                  </span>
                  <span class="accent--text">
                    {{ `${follower.firstName || 'ชื่อ'} ${follower.lastName || 'นามสกุล'}` }}
                  </span>
                </div>
              </v-btn>
              <v-btn
                color="primary"
                outlined
                block
                :ripple="false"
                depressed
                x-large
                :disabled="loading || isPaid"
                @click="addFollower()">
                <v-icon>
                  mdi-plus
                </v-icon>
                <span>
                  เพิ่มผู้ร่วมเดินทาง
                </span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="9">
              <v-row v-if="formData.followers.length">
                <v-col cols="12">
                  <div class="mb-1">
                    คำนำหน้า
                  </div>
                  <v-select
                    v-model="formData.followers[followerFocusIndex].prefix"
                    :rules="textBoxRules"
                    :items="prefixes"
                    item-text="text"
                    item-value="value"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    ชื่อ
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].firstName"
                    :rules="textBoxRules"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    นามสกุล
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].lastName"
                    :rules="textBoxRules"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    วัน เดือน ปีเกิด
                  </div>
                  <DatePickerDialog
                    v-model="formData.followers[followerFocusIndex].birthDate"
                    format="D MMMM BBBB"
                    :rules="textBoxRules"
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    หมายเลขโทรศัพท์
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].tel"
                    type="number"
                    :rules="[...textBoxRules, ...telBoxRules]"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    อีเมล
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].email"
                    :rules="emailBoxRules"
                    type="email"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].citizenNo"
                    :rules="textBoxRules"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="formData.followers[followerFocusIndex].age"
                    true-value="kid"
                    false-value="adult"
                    label="เด็กอายุ 0 - 12 ปี"
                    hide-details
                    class="mt-0"
                    :disabled="loading || isPaid" />

                  <v-checkbox
                    v-model="formData.followers[followerFocusIndex].includeTicket"
                    :true-value="false"
                    :false-value="true"
                    label="ไม่เอาตั๋วเครื่องบิน"
                    hide-details
                    :disabled="loading || isPaid" />
                </v-col>
                <v-col cols="12">
                  <div>
                    ประเภทห้อง
                  </div>
                  <v-divider class="my-2" />
                  <div
                    v-if="!tourRooms.length"
                    class="accent--text">
                    โปรดเลือกแพ็กเกจทัวร์
                  </div>
                  <v-radio-group
                    v-else
                    v-model="formData.followers[followerFocusIndex].room.name"
                    readonly
                    hide-details
                    class="mt-0"
                    :disabled="loading || isPaid">
                    <v-radio
                      v-for="room in tourRooms"
                      :key="`follower-room-${room.text}`"
                      :label="room.text"
                      :value="room.name"
                      readonly
                      :disabled="loading || isPaid"
                      @click="selectFollowerRoom(room)">
                      <template #label>
                        <div
                          class="d-flex flex-column flex-md-row align-md-center"
                          style="column-gap: 1rem;">
                          <span class="secondary--text">
                            {{ room.text }}
                          </span>
                          <span
                            v-if="room.price"
                            class="accent--text">
                            {{ `ราคา ${showNumberFormat(room.price)} บาท` }}
                          </span>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <div>
                    อัพเกรดที่นั่ง
                  </div>
                  <v-divider class="my-2" />
                  <div
                    v-if="!tourSeats.length"
                    class="accent--text">
                    โปรดเลือกแพ็กเกจทัวร์
                  </div>
                  <v-radio-group
                    v-else
                    v-model="formData.followers[followerFocusIndex].seat.name"
                    readonly
                    hide-details
                    class="mt-0"
                    :disabled="loading || isPaid">
                    <v-radio
                      v-for="seat in tourSeats"
                      :key="`follower-seat-${seat.text}`"
                      :label="seat.text"
                      :value="seat.name"
                      readonly
                      :disabled="loading || isPaid"
                      @click="selectFollowerSeat(seat)">
                      <template #label>
                        <div
                          class="d-flex flex-column flex-md-row align-md-center"
                          style="column-gap: 1rem;">
                          <span class="secondary--text">
                            {{ seat.text }}
                          </span>
                          <span
                            v-if="seat.price"
                            class="accent--text">
                            {{ `ราคา ${showNumberFormat(seat.price)} บาท` }}
                          </span>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <!-- <v-col cols="12">
                  <div>
                    ประกันภัย
                  </div>
                  <v-divider class="my-2" />
                  <div
                    v-if="!tourRooms.length"
                    class="accent--text">
                    โปรดเลือกแพ็กเกจทัวร์
                  </div>
                  <v-expansion-panels
                    v-else
                    class="rounded-lg mt-3"
                    style="border: 1px solid #026EAA;">
                    <v-expansion-panel
                      v-for="insurance in tourInsurances"
                      :key="`follower-insurance-${insurance.name}`"
                      class="rounded-lg no-shadow-panel">
                      <v-expansion-panel-header>
                        <v-checkbox
                          v-model="formData.followers[followerFocusIndex].insurances"
                          :value="insurance.name"
                          :ripple="false"
                          hide-details
                          class="mt-0" />
                        <span class="font-weight-medium">
                          {{ insurance.name }}
                        </span>
                        <span class="font-weight-medium">
                          ราคา {{ showNumberFormat(insurance.price) }} บาท
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="secondary--text">
                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                          <v-col cols="12">
                            <p class="font-weight-medium mb-2">
                              รายละเอียด
                            </p>
                            <p class="accent--text mb-2">
                              {{ insurance.description }}
                            </p>
                            <p class="font-weight-medium mb-2">
                              ความคุ้มครอง
                            </p>
                            <p class="accent--text mb-0">
                              {{ insurance.protections }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col> -->
                <v-col
                  cols="12"
                  class="d-flex justify-end">
                  <v-btn
                    color="error"
                    outlined
                    :ripple="false"
                    depressed
                    :disabled="loading || isPaid"
                    @click="removeFollower()">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                    <span>
                      ลบผู้ร่วมเดินทาง
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
              <div
                v-else
                class="accent--text d-flex justify-center align-center"
                style="height: 100%;">
                ไม่มีผู้ร่วมเดินทาง
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <h3>
            ที่อยู่สำหรับจัดส่งเอกสาร
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            ชื่อ
          </div>
          <v-text-field
            v-model="formData.documentAddress.firstName"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            นามสกุล
          </div>
          <v-text-field
            v-model="formData.documentAddress.lastName"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            หมายเลขโทรศัพท์
          </div>
          <v-text-field
            v-model="formData.documentAddress.tel"
            type="number"
            :rules="telBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            ที่อยู่
          </div>
          <v-text-field
            v-model="formData.documentAddress.address"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            ถนน
          </div>
          <v-text-field
            v-model="formData.documentAddress.road"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            ซอย
          </div>
          <v-text-field
            v-model="formData.documentAddress.alley"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            แขวง / ตำบล
          </div>
          <v-text-field
            v-model="formData.documentAddress.subDistrict"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            เขต / อำเภอ
          </div>
          <v-text-field
            v-model="formData.documentAddress.district"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            จังหวัด
          </div>
          <v-text-field
            v-model="formData.documentAddress.province"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            รหัสไปรษณีย์
          </div>
          <v-text-field
            v-model="formData.documentAddress.postcode"
            type="number"
            :rules="postcodeRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col cols="12">
          <h3>
            สรุปออเดอร์
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            เลขที่แพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.id }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            ชื่อแพ็กเกจ
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.name }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            เวนเดอร์
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.vendor.name }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            จำนวนคนทั้งหมด
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span>
            {{ `${formData.followers.length + 1} คน` }}
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                ผู้ใหญ่
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span>
            {{ `${getAdults().length} คน` }}
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                เด็ก
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span>
            {{ `${getKids().length} คน` }}
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            ประเภททัวร์
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.type }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ราคาผู้ใหญ่
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('adult').price)} x ${getTickets('adult').quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('adult').net)}` }}
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                ไม่รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('adult').withOutTicket)} x ${getTickets('adult', false).quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('adult', false).net)}` }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ราคาเด็ก
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('kid').price)} x ${getTickets('kid').quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('kid').net)}` }}
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                ไม่รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('kid').withOutTicket)} x ${getTickets('kid', false).quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('kid', false).net)}` }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ประเภทห้อง
          </span>
        </v-col>
        <template v-for="(room, i) in getRooms()">
          <v-col
            :key="`room-name-${i}`"
            cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  {{ room.name }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            :key="`room-quantity-${i}`"
            cols="4"
            md="3">
            <span class="accent--text">
              {{ `${showNumberFormat(room.price)} x ${room.quantity}` }}
            </span>
          </v-col>
          <v-col
            :key="`room-net-${i}`"
            cols="4"
            md="3"
            class="text-right">
            <span>
              {{ `฿ ${showNumberFormat(room.net)}` }}
            </span>
          </v-col>
        </template>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            อัพเกรดที่นั่ง
          </span>
        </v-col>
        <template v-for="(seat, i) in getSeats()">
          <v-col
            :key="`seat-name-${i}`"
            cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  {{ seat.name }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            :key="`seat-quantity-${i}`"
            cols="4"
            md="3">
            <span class="accent--text">
              {{ `${showNumberFormat(seat.price)} x ${seat.quantity}` }}
            </span>
          </v-col>
          <v-col
            :key="`seat-net-${i}`"
            cols="4"
            md="3"
            class="text-right">
            <span>
              {{ `฿ ${showNumberFormat(seat.net)}` }}
            </span>
          </v-col>
        </template>
        <!-- <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ประกันภัย
          </span>
        </v-col>
        <template v-for="ins in getInsurances()">
          <v-col
            :key="`col-1-${ins.name}`"
            cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  {{ ins.name }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            :key="`col-2-${ins.name}`"
            cols="4"
            md="3">
            <span class="accent--text">
              {{ `${showNumberFormat(ins.price)} x ${ins.quantity}` }}
            </span>
          </v-col>
          <v-col
            :key="`col-3-${ins.name}`"
            cols="4"
            md="3"
            class="text-right">
            <span>
              {{ `฿ ${showNumberFormat(ins.net)}` }}
            </span>
          </v-col>
        </template> -->
        <!-- <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="4">
          <span class="font-weight-bold">
            โค้ดส่วนลด
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <button
            class="discount-btn"
            type="button"
            @click="showDiscountDialog()">
            {{ formData.discountCode || 'กรอกโค้ดส่วนลด' }}
          </button>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <v-text-field
            v-model.number="formData.discountPrice"
            type="number"
            color="primary"
            prepend-inner-icon="mdi-currency-thb"
            :rules="[
              (v) => v >= 0 || 'กรุณาระบุไม่ต่ำกว่า 0'
            ]"
            outlined
            hide-details
            dense />
        </v-col> -->
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="4">
          <span class="font-weight-bold">
            ปรับยอดรวม
          </span>
        </v-col>
        <v-col cols="4">
        </v-col>
        <v-col
          cols="4"
          md="2">
          <v-text-field
            v-model.number="formData.adjustPrice"
            type="number"
            color="primary"
            :prepend-inner-icon="formData.adjustType === 'add' ? 'mdi-plus' : 'mdi-minus'"
            :rules="[
              (v) => v >= 0 || 'กรุณาระบุไม่ต่ำกว่า 0'
            ]"
            outlined
            hide-details
            dense
            :disabled="loading || isPaid"
            @click:prepend-inner="toggleAdjustType()" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            ราคารวมสุทธิ
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7"
          class="text-right">
          <span class="font-weight-bold">
            {{ `฿ ${showNumberFormat(formData.net)}` }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="checkAdjustNote()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <discount-dialog v-model="isShowDiscountDialog" />
    <adjust-note-dialog
      v-model="isShowAdjustNoteDialog"
      :text="formData.adjustNote"
      @confirm="onConfirm($event)" />
  </v-container>
</template>

<script>
import OrdersProvider from '@/resources/orders.provider'
import ToursProvider from '@/resources/tours.provider'
import MembersProvider from '@/resources/members.provider'
import UploaderProvider from '@/resources/uploader.provider'
import DatePickerDialog from '@/components/DatePickerDialog.vue'
import FileUploader from '@/components/FileUploader.vue'
import DiscountDialog from '../components/DiscountDialog.vue'
import AdjustNoteDialog from '../components/AdjustNoteDialog.vue'

const OrdersService = new OrdersProvider()
const ToursService = new ToursProvider()
const MembersService = new MembersProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    DatePickerDialog,
    FileUploader,
    DiscountDialog,
    AdjustNoteDialog
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    telBoxRules: [
      (v) => (v ? (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง') : true)
    ],
    emailBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
      (v) => /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    postcodeRules: [
      (v) => `${v}`.length <= 5 || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    formData: {
      orderNo: '',
      slipImageName: '',
      slipImageUrl: '',
      tour: null,
      member: null,
      package: null,
      booker: {
        age: 'adult',
        memberId: '',
        prefix: 'mr',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        tel: '',
        citizenNo: '',
        room: {
          name: '',
          price: 0
        },
        insurances: [],
        seat: {
          name: '',
          price: 0
        },
        meal: {
          name: '',
          price: 0
        },
        includeTicket: true
      },
      followers: [],
      documentAddress: {
        firstName: '',
        lastName: '',
        tel: '',
        address: '',
        road: '',
        alley: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: ''
      },
      note: '',
      discountCode: '',
      discountPrice: 0,
      adjustPrice: 0,
      adjustType: 'add',
      adjustNote: '',
      net: 0,
      state: 'pending',
      payments: []
    },
    ages: [
      {
        text: 'ผู้ใหญ่',
        value: 'adult'
      },
      {
        text: 'เด็ก 0 - 12 ปี',
        value: 'kid'
      }
    ],
    states: [
      {
        text: 'รอตรวจสอบเอกสาร',
        value: 'inspecting'
      },
      {
        text: 'รอยืนยันการจอง',
        value: 'pending'
      },
      {
        text: 'รอชำระเงิน',
        value: 'confirmed'
      },
      {
        text: 'ชำระเงินเสร็จสิ้น',
        value: 'paid'
      },
      {
        text: 'ยกเลิก',
        value: 'canceled'
      }
    ],
    prefixes: [
      {
        text: 'นาย',
        value: 'mr'
      },
      {
        text: 'นาง',
        value: 'ms'
      },
      {
        text: 'นางสาว',
        value: 'mrs'
      }
    ],
    files: [],
    tours: [],
    followerFocusIndex: -1,
    memoAdjustPrice: 0,
    memoAdjustType: 'add',
    isShowDiscountDialog: false,
    isShowAdjustNoteDialog: false,
    isPaid: false
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    },
    tourRooms () {
      if (!this.formData.tour?.rooms?.length) {
        return []
      }

      return [
        {
          text: 'ไม่ระบุ',
          name: '',
          price: 0
        },
        ...this.formData.tour.rooms.map((r) => ({
          text: r.name,
          name: r.name,
          price: r.price
        }))
      ]
    },
    tourSeats () {
      if (!this.formData.tour?.seats?.length) {
        return []
      }

      return [
        {
          text: 'ไม่ระบุ',
          name: '',
          price: 0
        },
        ...this.formData.tour.seats.map((r) => ({
          text: r.name,
          name: r.name,
          price: r.price
        }))
      ]
    },
    tourInsurances () {
      return this.formData.tour?.insurances || []
    },
    packageText () {
      if (!this.formData.package?.startDate || !this.formData.package?.endDate) {
        return ''
      }

      const startDate = this.$options.filters.dateFormat(this.formData.package.startDate)
      const endDate = this.$options.filters.dateFormat(this.formData.package.endDate)

      return `${startDate} - ${endDate}`
    }
  },
  watch: {
    'formData': {
      handler () {
        this.calcPrice()
      },
      deep: true
    },
    'formData.tour': {
      handler () {
        this.formData.package = this.formData.tour?.package || null
      },
      deep: true
    }
  },
  async mounted () {
    await this.getTours()

    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'OrderList' })
    },
    getFollowers (includeBooker = true) {
      if (includeBooker) {
        return [
          this.formData.booker,
          ...this.formData.followers
        ]
      }

      return this.formData.followers
    },
    getPackage (type) {
      return this.formData.package && this.formData.package[type]
        ? this.formData.package[type]
        : {
            price: 0,
            withOutTicket: 0
          }
    },
    getAdults () {
      return this.getFollowers().filter((t) => t.age === 'adult')
    },
    getKids () {
      return this.getFollowers().filter((t) => t.age === 'kid')
    },
    getTickets (type, includeTicket = true) {
      const travelers = type === 'adult' ? this.getAdults() : this.getKids()
      const quantity = travelers.filter((t) => t.includeTicket === includeTicket).length

      return {
        quantity,
        net: includeTicket ? this.getPackage(type).price * quantity : this.getPackage(type).withOutTicket * quantity
      }
    },
    getRooms () {
      const travelers = this.getFollowers()

      return this.tourRooms
        // .filter((r) => r.price > 0)
        .map((r) => {
          const found = travelers.filter((t) => t.room?.name === r.name)
          const net = found.reduce((total, t) => total + t.room.price, 0)

          return {
            name: r.name,
            price: r.price,
            quantity: found.length,
            net
          }
        })
        .filter((r) => !!r.name)
        // .filter((r) => r.quantity > 0)
    },
    getSeats () {
      const travelers = this.getFollowers()

      return this.tourSeats
        // .filter((r) => r.price > 0)
        .map((r) => {
          const found = travelers.filter((t) => t.seat?.name === r.name)
          const net = found.reduce((total, t) => total + t.seat.price, 0)

          return {
            name: r.name,
            price: r.price,
            quantity: found.length,
            net
          }
        })
        .filter((r) => !!r.name)
        // .filter((r) => r.quantity > 0)
    },
    getInsurances () {
      if (!this.formData.tour) {
        return []
      }

      const travelers = this.getFollowers()
      const mapped = travelers.reduce((obj, t) => {
        const tmpObj = { ...obj }
        const insurances = this.formData.tour.insurances.filter((i) => t.insurances.includes(i.name))

        insurances.forEach((i) => {
          const quantity = (tmpObj[i.name]?.quantity || 0) + 1

          tmpObj[i.name] = {
            name: i.name,
            price: i.price,
            quantity,
            net: quantity * i.price
          }
        })

        return tmpObj
      }, {})

      return Object.values(mapped)
    },
    calcPrice () {
      if (!this.formData.tour || !this.formData.package) {
        return
      }

      const travelers = this.getFollowers()
      const bookingNet = travelers.reduce((total, follower) => {
        let sum = 0

        if (follower.age === 'kid') {
          sum += follower.includeTicket ? this.formData.package.kid.price : this.formData.package.kid.withOutTicket
        } else {
          sum += follower.includeTicket ? this.formData.package.adult.price : this.formData.package.adult.withOutTicket
        }

        if (follower.room) {
          sum += follower.room.price
        }

        if (follower.seat) {
          sum += follower.seat.price
        }

        if (follower.meal) {
          sum += follower.meal.price || 0
        }

        if (follower.insurances.length) {
          const insurances = this.formData.tour.insurances.filter((i) => follower.insurances.includes(i.name))
          sum += insurances.reduce((insuranceTotal, i) => insuranceTotal + i.price, 0)
        }

        return total + sum
      }, 0)

      const discountPrice = this.formData.discountPrice < 0 ? 0 : this.formData.discountPrice
      const adjustPrice = this.formData.adjustPrice < 0 ? 0 : this.formData.adjustPrice

      if (this.formData.adjustType === 'add') {
        this.formData.net = bookingNet + adjustPrice - discountPrice
      } else {
        this.formData.net = bookingNet - adjustPrice - discountPrice
      }
    },
    selectRoom (room) {
      this.formData.booker.room = room
    },
    selectSeat (seat) {
      this.formData.booker.seat = seat
    },
    selectFollowerRoom (room) {
      this.formData.followers[this.followerFocusIndex].room = room
    },
    selectFollowerSeat (seat) {
      this.formData.followers[this.followerFocusIndex].seat = seat
    },
    showNumberFormat (number) {
      const num = Number.parseFloat(number)

      if (num === 0 || num) {
        return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '-'
    },
    selectFollower (index) {
      this.followerFocusIndex = index
    },
    addFollower () {
      this.formData.followers.push({
        age: 'adult',
        prefix: 'mr',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        tel: '',
        citizenNo: '',
        room: {
          name: '',
          price: 0
        },
        insurances: [],
        seat: {
          name: '',
          price: 0
        },
        meal: {
          name: '',
          price: 0
        },
        includeTicket: true
      })

      this.followerFocusIndex++
    },
    removeFollower () {
      const tmpIndex = this.followerFocusIndex
      this.followerFocusIndex = tmpIndex - 1
      this.formData.followers.splice(tmpIndex, 1)
    },
    async getTours () {
      try {
        this.loading = true

        const { data } = await ToursService.getItems({
          page: 1,
          limit: 99999
        })

        this.tours = data.results
      } catch (error) {
        console.error('getTours', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await OrdersService.getItemByOrderNo(this.$route.params.id)

        if (data.followers.length) {
          this.followerFocusIndex = 0
        }

        this.isPaid = data.state === 'paid'
        this.formData = {
            ...data,
            tour: this.tours.find((t) => t.id === data.tour.id),
            booker: {
              ...data.booker,
              insurances: data.booker.insurances.map((i) => i.name)
            },
            followers: data.followers.map((f) => ({
              ...f,
              insurances: f.insurances.map((i) => i.name)
            })),
            adjustPrice: data?.adjustPrice || 0,
            adjustType: data?.adjustType || 'add',
            adjustNote: data?.adjustNote || ''
          }
        this.memoAdjustPrice = data?.adjustPrice || 0
        this.memoAdjustType = data?.adjustType || 'add'
        this.files = data.slipImageUrl ? [
          {
            name: data.slipImageName,
            mediaUrl: data.slipImageUrl
          }
        ] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        const slipImageName = this.files[0]?.name || ''
        let slipImageUrl = this.files[0]?.mediaUrl || ''

        if (this.files[0]?.file) {
          const { data: image } = await UploaderService.uploadFile(this.files[0].file)

          slipImageUrl = image?.publicUrl
        }

        let member = this.formData.member || null

        if (this.formData.booker.memberId) {
          const { data } = await MembersService.getItemById(this.formData.booker.memberId)

          member = data
        }

        if (!this.isEdit) {
          await OrdersService.create({
            ...this.formData,
            member,
            booker: {
              ...this.formData.booker,
              insurances: this.formData.tour.insurances.filter((i) => this.formData.booker.insurances.includes(i.name))
            },
            followers: this.formData.followers.map((f) => ({
              ...f,
              insurances: this.formData.tour.insurances.filter((i) => f.insurances.includes(i.name))
            })),
            slipImageName,
            slipImageUrl
          })
        } else {
          await OrdersService.updateItemById({
            ...this.formData,
            member,
            booker: {
              ...this.formData.booker,
              insurances: this.formData.tour.insurances.filter((i) => this.formData.booker.insurances.includes(i.name))
            },
            followers: this.formData.followers.map((f) => ({
              ...f,
              insurances: this.formData.tour.insurances.filter((i) => f.insurances.includes(i.name))
            })),
            slipImageName,
            slipImageUrl
          })
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'OrderList' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async checkAdjustNote () {
      const valid = await this.$refs['form'].validate()

      if (!valid) {
        return
      }

      if (this.formData.adjustPrice !== this.memoAdjustPrice || this.formData.adjustType !== this.memoAdjustType) {
        this.showAdjustNoteDialog()
      } else {
        this.onSubmit()
      }
    },
    onConfirm (text) {
      this.formData.adjustNote = text
      this.onSubmit()
    },
    showDiscountDialog () {
      this.isShowDiscountDialog = !this.isShowDiscountDialog
    },
    showAdjustNoteDialog () {
      this.isShowAdjustNoteDialog = !this.isShowAdjustNoteDialog
    },
    toggleAdjustType () {
      this.formData.adjustType = this.formData.adjustType === 'add' ? 'minus' : 'add'
    }
  }
}
</script>

<style scoped>
.no-shadow-panel::before {
  box-shadow: none !important;
}
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.v-size--x-large {
  height: 64px !important;
  border-radius: 8px !important;
  letter-spacing: 0em !important;
}
.discount-btn {
  font-size: 14px;
  color: #026EAA;
  cursor: pointer;
  text-decoration: underline;
}
</style>
