<template>
  <v-dialog
    v-model="modal"
    max-width="400">
    <v-form
      ref="dialog-form"
      @submit.prevent="onConfirm()">
      <v-card>
        <v-card-title>
          หมายเหตุ
        </v-card-title>

        <v-card-text>
          <v-divider class="mb-3" />
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData"
                color="primary"
                :rules="[
                  (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
                ]"
                label="หมายเหตุ"
                outlined
                hide-details
                dense />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <div class="button-container">
            <v-btn
              color="primary"
              block
              :ripple="false"
              depressed
              @click="onConfirm()">
              ยืนยัน
            </v-btn>
            <v-btn
              color="primary"
              outlined
              block
              :ripple="false"
              depressed
              style="background-color: white;"
              @click="onCancel()">
              ยกเลิก
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    formData: ''
  }),
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    text () {
      this.formData = this.text
    }
  },
  mounted () {
    this.formData = this.text
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    async onConfirm () {
      const valid = await this.$refs['dialog-form'].validate()

      if (!valid) {
        return
      }

      this.modal = false
      this.$emit('confirm', this.formData)
    },
    onCancel () {
      this.modal = false
    }
  }
}
</script>

<style scoped>
.button-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
}
</style>
