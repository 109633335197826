<template>
  <v-dialog
    v-model="modal"
    max-width="80vw">
    <v-card>
      <v-card-title>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="auto">
            สิทธิพิเศษ
          </v-col>
          <v-col
            cols="12"
            md="4">
            <SearchBox
              v-model="query.search"
              :disabled="loading"
              @search="getItems(true)" />
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-divider class="mb-3" />
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              hide-default-footer
              class="table-border">
              <template #[`item.createdAt`]="{ item }">
                {{ item.createdAt | dateFullFormat() }}
              </template>
              <template #[`item.status`]="{ item }">
                <span :class="checkStatus(item).color">
                  {{ checkStatus(item).text }}
                </span>
              </template>
              <template #[`item.forRank`]="{ item }">
                <span class="text-capitalize">
                  {{ item.forRank || 'ใช้ได้ทุกระดับ' }}
                </span>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  icon
                  :disabled="loading"
                  @click="selectItem(item)">
                  <v-icon>
                    mdi-button-pointer
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-pagination
                v-model="query.page"
                :length="totalPages"
                :total-visible="7"
                circle
                color="primary" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import PrivilegesProvider from '@/resources/privileges.provider'
import SearchBox from '@/components/SearchBox.vue'

const PrivilegesService = new PrivilegesProvider()

export default {
  components: {
    SearchBox
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'วันที่ทำรายการ',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ชื่อสิทธิพิเศษ',
        value: 'name',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'table-header',
        align: 'right',
        sortable: false
      },
      {
        text: 'ระดับลูกค้า',
        value: 'forRank',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: ''
    },
    totalPages: 1
  }),
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    checkStatus (item) {
      const today = this.$dayjs()

      if (this.$dayjs(item.endDate).isBefore(today)) {
        return {
          text: 'หมดอายุ',
          color: 'accent--text'
        }
      }

      if (this.$dayjs(today).isBefore(item.startDate)) {
        return {
          text: 'ยังไม่ถึงกำหนด',
          color: 'gold--text'
        }
      }

      return {
        text: 'ช่วงโปรโมชัน',
        color: 'success--text'
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await PrivilegesService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    selectItem (item) {
      this.$emit('select', item)
      this.modal = false
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.order-button {
  font-weight: bold;
  cursor: pointer;
}
</style>
